/* eslint-disable no-unused-expressions */
<template>
  <v-container>
    <the-dialog-box
      ref="dialogBox"
      :submit="submit"
      :max-width="600"
      :persistent="true"
      :isSubmitBtn="false"
      :isCancelBtn="false"
    >
      <template slot="header">
        <span>Moving Multi-Product</span>
      </template>
      <template slot="body">
        <v-row
          class="pa-0"
          justify="center"
          align="center"
          style="font-size: 1.3rem"
        >
          <v-col
            cols="3"
            style="text-align:center"
          >
            <h5 class="h5">
              QR Code
            </h5> <span class="value"> {{ mpqr && mpqr.inventory_tag ? mpqr.inventory_tag.inventory_tag : '' }}</span>
          </v-col>
          <v-col
            cols="3"
            style="text-align:center"
          >
            <h5 class="h5">
              Item Name
            </h5> <span class="value"> {{ mpqr && mpqr.glass_paint_code ? `${mpqr.glass_paint_code.code} ${mpqr.glass_paint_code.description}` : '' }}</span>
          </v-col>
          <v-col
            cols="3"
            style="text-align:center"
          >
            <h5 class="h5">
              Project
            </h5>
            <span class="value"> {{ mpqr && mpqr.project ? mpqr.project.name : '' }}</span>
          </v-col>
          <v-col
            cols="3"
            style="text-align:center"
          >
            <h5 class="h5">
              UOM
            </h5>
            <span class="value"> {{ mpqr && mpqr.uom ? mpqr.uom.name : '' }}</span>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          align="center"
          style="font-size: 1.3rem"
        >
          <v-col cols="auto">
            <v-row
              v-if="scannedQrCode"
              justify="center"
              align="center"
            >
              <v-col
                cols="auto"
                class="mt-3"
              >
                <h3>Moving to: {{ scannedQrCode }}</h3>
              </v-col>
            </v-row>
            <v-form ref="form">
              <v-row class="mt-0">
                <v-col>
                  <v-row
                    justify="center"
                    align="center"
                  >
                    <v-col
                      cols="6"
                      sm="6"
                      style="text-align:center;"
                    >
                      <h5 class="h5">
                        Quantity
                      </h5>
                      <span class="value"> {{ mpqr ? mpqr.quantity : '' }}</span>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      class="pb-0"
                      alig
                    >
                      <h5 class="h5">
                        Moving Quantity<RedAsterisk />
                      </h5>
                      <v-text-field
                        v-model="quantity"
                        :rules="[(v) => !!v || 'Moving Quantity is required']"
                        required
                        outlined
                        single-line
                        type="number"
                      >
                        <template #label>
                          Moving Quantity
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row
                    justify="center"
                    align="center"
                  >
                    <v-col
                      cols="auto"
                      class="pt-0"
                    >
                      <span
                        v-if="scannedQrCode"
                      ><span class="font-weight-bold">IMPORTANT!</span> QR Code <span class="font-weight-bold">{{ scannedQrCode }} </span>will be used for Inventory Movement</span>
                      <span
                        v-else
                        class="red--text"
                      >{{ errorMessage }}</span>
                    </v-col>
                  </v-row>
                  <v-row
                    justify="center"
                    align="center"
                  >
                    <v-col
                      cols="auto"
                    >
                      <v-btn
                        :disabled="!isScanQrMoveActive"
                        color="primary"
                        x-large
                        class="mr-4"
                        @click="scanQrCode"
                      >
                        scan qr
                      </v-btn>
                      <v-btn
                        :disabled="!isSubmitBtnActive"
                        color="primary"
                        x-large
                        class="mr-4"
                        @click="submit"
                      >
                        submit
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mr-4"
                        outlined
                        x-large
                        @click="$refs.dialogBox.close()"
                      >
                        cancel
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </template>
    </the-dialog-box>
    <v-dialog
      v-model="qrCodeDialog"
      fullscreen
      persistent
      hide-overlay
    >
      <v-card>
        <v-card-title>
          <v-col class="text-right">
            <v-btn
              icon
              @click="qrCodeDialog = false"
            >
              <v-icon
                large
                dark
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-container fluid>
          <inventory-qr
            :is-rm-supply="true"
            :is-moving-qr-partially="true"
            @return-qr-code-move="returnQrCode"
          />
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import InventoryQr from '@/views/InventoryQrs/InventoryQr';
import { mapState } from 'vuex';

export default {
  components: {
    InventoryQr,
  },
  data: () => ({
    mpqr: null,
    items: [],
    headers: [
      { text: 'Item', value: 'glass_paint_code.code' },
      { text: 'Description', value: 'glass_paint_code.description' },
      { text: 'Project', value: 'project.name' },
      { text: 'Quantity', value: 'quantity' },
      { text: 'UOM', value: 'uom.name' },
    ],
    qrCode: '',
    quantity: 0,
    inventoryLocation: null,
    inventoryLocations: [],
    filteredInventoryLocations: [],
    scannedInventoryTag: null,
    scannedQrCode: null,
    inventoryClassIdGlass: 5,
    inventoryClassIdVWDGlass: 7,
    inventoryClassIdPanels: 8,
    inventoryClassIdSID: 9,
    inventoryClassIdPaint: 12,
    onTruckLocationId: 9,
    errorMessage: '',
    isSubmitBtnActive: true,
    isScanQrMoveActive: true,
    isActivatedVisible: false,
    qrCodeDialog: false,
    isCreateNewInventoryTag: false,
    isUpc: false,
    inventoryIdSlab: 1100,
    inventoryIdSweep: 1101,
    inventoryIdInsert: 1102,
    topErrorMessage: 'THIS QR CODE DOES NOT EXIST IN THE SYSTEM. PLEASE SCAN A VALID QR CODE.',
  }),
  computed: {
    ...mapState({
      user: 'user',
    }),
  },
  watch: {
    quantity(value) {
      const qtyOnHand = parseInt(this.mpqr.quantity, 10);
      const quantity = parseInt(value, 10);

      this.isSubmitBtnActive = true;
      this.errorMessage = '';
      this.isScanQrMoveActive = true;
      this.scannedQrCode = null;
      this.scannedInventoryTag = null;
      this.isCreateNewInventoryTag = false;
      if (qtyOnHand < 0) {
        this.errorMessage = 'ERROR! Moving Quantity On Hand must be greater than 0';
        this.isSubmitBtnActive = false;
      } else if (quantity < 0) {
        this.errorMessage = 'ERROR! Moving Quantity must be greater than 0';
        this.isSubmitBtnActive = false;
      // eslint-disable-next-line eqeqeq
      } else if (quantity == 0) {
        this.errorMessage = 'ERROR! Moving Quantity must be greater than 0';
        this.isSubmitBtnActive = false;
      } else if (quantity > qtyOnHand) {
        this.errorMessage = 'ERROR! Moving Quantity cannot be greater than Quantity On Hand';
        this.isSubmitBtnActive = false;

      // If moving partially, then scan a qr code
      } else if (quantity < qtyOnHand) {
        this.errorMessage = 'IMPORTANT! You\'re moving an incomplete bundle. Please SCAN QR using button below to activate new code.';
        this.isScanQrMoveActive = true;
        this.isSubmitBtnActive = false;
      }
    },
  },
  created() {
    this.qrCode = this.$route.params.code;
    this.init();
  },

  methods: {
    async init() {
      await this.getLocations();
      await this.getInventoryLocations();
    },

    // QR CODE IS THE SAME AS INVENTORY TAG
    async getMPQR(item) {
      if (!item.inventory_tag || !item.glass_paint_code_id) return;
      const submitModel = {
        filters: {
          inventory_tag: item.inventory_tag,
          glass_paint_code_id: item.glass_paint_code_id,
          uom_id: item.uom_id,
          project_id: item.project_id,
        },

        is_moving_mpqr: true,
      };

      const res = await this.$api.inventoryMPQR.read(submitModel);
      if (res && res.status === 505) {
        this.$bus.$emit('showError', 'Could not retrieve MPQR');
        this.$refs.dialogBox.close();
        return;
      }
      if (!res || !res[0]) {
        this.$bus.$emit('showError', 'Could not retrieve MPQR');
        this.$refs.dialogBox.close();
        return;
      }
      this.mpqr = res[0];
      this.items = res;
      const data = res[0];
      this.isActivatedVisible = false;
      this.quantity = data.quantity;
    },

    async submit() {
      if (!this.mpqr) return;
      if (!await this.$refs.form.validate()) return;

      this.$bus.$emit('loading');
      // const inventoryClassId = this.mpqr && this.mpqr.item && this.mpqr.item.classes ? this.mpqr.item.classes.id : 0;

      // If moving partially and new Qr Code being used, else use the old QR Code
      const qrCode = this.scannedQrCode ? this.scannedQrCode : this.qrCode;

      const submitData = {
        // Tag parameters
        inventory_tag: qrCode,
        inventory_location_id: this.scannedInventoryTag.inventory_location.id,
        location_id: this.scannedInventoryTag.location.id,
        inventory_class_id: this.inventoryClassIdPaint,

        // MPQR parameters
        glass_paint_code_id: this.mpqr.glass_paint_code_id,
        uom_id: this.mpqr.uom_id,
        project_id: this.mpqr.project_id,

        // Movement Parameters
        qr_code: qrCode,
        created_by: this.user.id,
        quantity: this.quantity,

        // Supply Parameters
        consumption_id: 0,
        old_qr_code: this.qrCode,
        // If moving to existing tag
        is_scanned_exist: this.isCreateNewInventoryTag === true ? 0 : 1,
        is_mpqr_exist: this.isCreateNewMPQR === true ? 0 : 1,
      };

      const res = await this.$api.inventoryMPQR.submitMovement(submitData);
      this.$bus.$emit('loading-stop');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.$refs.dialogBox.close();
      this.$emit('success');
    },

    scanQrCode() {
      this.qrCodeDialog = true;
    },

    // Can be two options if moving partially:
    // merge moving tag into existing one (parameters must be the same)
    // or create a new tag
    async returnQrCode(qrCode) {
      if (!qrCode) return;
      if (qrCode <= 199999 || qrCode >= 209999) {
        this.$bus.$emit('showError', 'Please scan an existing MPQR');
        return;
      }
      this.qrCodeDialog = false;
      this.scannedQrCode = null;
      this.isSubmitBtnActive = false;
      this.isCreateNewInventoryTag = false;

      // Get Scanned qr code if moving partially
      const submitModel = {
        filters: {
          inventory_tag: qrCode,
          is_qty_on_hand_o: 0,
        },
      };

      const resRead = await this.$api.inventoryTag.read(submitModel);
      let res = null;
      if (resRead && resRead.data && resRead.data.length !== 0) {
        const firstIndex = 0;
        res = resRead.data[firstIndex];
      } else {
        this.$bus.$emit('showError', 'QR code does not exist. Needs to be activated first.');
        return;
      }

      // If scanned tag exist
      if (res) {
        // Check if qr code has the same item parameters as Moving Qr Code and if inventory tag has the same mpqr
        const mpqr = this.checkInventoryTag(res);
        if (!mpqr) {
          this.isCreateNewMPQR = true;
        }
        this.isSubmitBtnActive = true;
        this.scannedQrCode = qrCode;
        this.scannedInventoryTag = res;
      // else scanned tag is missing
      } else {
        this.isCreateNewInventoryTag = true;
        this.isCreateNewMPQR = true,
        this.scannedQrCode = qrCode;
        this.isSubmitBtnActive = true;
      }
    },

    checkInventoryTag(tag) {
      // if quanitty is 0, need to remove it from this mpqr when moving
      if (!tag.multi_products || tag.multi_products.length < 1) return null;
      const mpqr = tag.multi_products.find((x) => x.glass_paint_code_id === this.mpqr.glass_paint_code_id && x.uom_id === this.mpqr.uom_id && x.project_id === this.mpqr.project_id);
      if (mpqr) {
        return mpqr;
      }
      return null;
    },

    async open(item) {
      this.$refs.dialogBox.open();
      await this.getMPQR(item);
    },
  },
};
</script>
<style scoped>
.v-text-field >>> input {
    font-size: 1.5em !important;
}
.v-btn {
    font-size: 1.1em !important;
}

</style>
